<template>
  <div class="airdrop">
    <el-carousel
      v-if="carouselitem.length > 0 && !isMobile"
      height="140px"
      arrow="never"
      :indicator-position="carouselitem.length > 1 ? 'outside' : 'none'"
      :autoplay="true"
      :interval="2000"
    >
      <el-carousel-item v-for="(item, index) in carouselitem" :key="index">
        <div class="banner">
          <div
            :class="[
              'box1 box',
              Object.keys(item).length == 4 ? 'box' : '',
              Object.keys(item).length == 3 ? 'wid32' : '',
              Object.keys(item).length == 2 ? 'wid50' : '',
              Object.keys(item).length == 1 ? 'wid100' : '',
            ]"
            @click="Tothedetails(item.list0.id)"
          >
            <div class="quankg">
              <div class="leiquan"></div>
            </div>
            <div class="a-row">
              <p>{{ item.list0.name }}</p>
              <div class="shady">
                <img
                  :src="
                    item.list0.logo
                      ? item.list0.logo
                      : require('../../static/images/18.png')
                  "
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <p class="The-second-row">
              {{ $tool.formatMonenyNumber(item.list0.totalAirdropAmount) }}
              {{ " " + item.list0.token }}
            </p>
            <p class="triplex-row">
              {{ $t("airdrop.NumberofWinners") }}:{{
                item.list0.winnerAddressNumber
              }}
            </p>
          </div>
          <div
            :class="[
              'box2 box',
              Object.keys(item).length == 4 ? 'box' : '',
              Object.keys(item).length == 3 ? 'box2-wid32 ' : '',
              Object.keys(item).length == 2 ? 'box2-wid50' : '',
              Object.keys(item).length == 1 ? 'wid100' : '',
            ]"
            @click="Tothedetails(item.list1.id)"
            v-if="item.list1"
          >
            <div class="a-row">
              <p>{{ item.list1.name }}</p>
              <div class="shady">
                <img
                  :src="
                    item.list1.logo
                      ? item.list1.logo
                      : require('../../static/images/18.png')
                  "
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <p class="The-second-row">
              {{ $tool.formatMonenyNumber(item.list1.totalAirdropAmount) }}
              {{ " " + item.list1.token }}
            </p>
            <p class="triplex-row">
              {{ $t("airdrop.NumberofWinners") }}:{{
                item.list1.winnerAddressNumber
              }}
            </p>
          </div>
          <div
            :class="[
              'box3 box',
              Object.keys(item).length == 4 ? 'box' : '',
              Object.keys(item).length == 3 ? 'box3-wid32' : '',
              Object.keys(item).length == 2 ? 'wid50' : '',
              Object.keys(item).length == 1 ? 'wid100' : '',
            ]"
            @click="Tothedetails(item.list2.id)"
            v-if="item.list2"
          >
            <div class="a-row">
              <p>{{ item.list2.name }}</p>
              <div class="shady">
                <img
                  :src="
                    item.list2.logo
                      ? item.list2.logo
                      : require('../../static/images/18.png')
                  "
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <p class="The-second-row">
              {{ $tool.formatMonenyNumber(item.list2.totalAirdropAmount) }}
              {{ " " + item.list2.token }}
            </p>
            <p class="triplex-row">
              {{ $t("airdrop.NumberofWinners") }}:{{
                item.list2.winnerAddressNumber
              }}
            </p>
          </div>
          <div
            :class="[
              'box4 box',
              Object.keys(item).length == 4 ? 'box' : '',
              Object.keys(item).length == 3 ? 'wid32' : '',
              Object.keys(item).length == 2 ? 'wid50' : '',
              Object.keys(item).length == 1 ? 'wid100' : '',
            ]"
            @click="Tothedetails(item.list3.id)"
            v-if="item.list3"
          >
            <div class="a-row">
              <p>{{ item.list3.name }}</p>
              <div class="shady">
                <img
                  :src="
                    item.list3.logo
                      ? item.list3.logo
                      : require('../../static/images/18.png')
                  "
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <p class="The-second-row">
              {{ $tool.formatMonenyNumber(item.list3.totalAirdropAmount) }}
              {{ " " + item.list3.token }}
            </p>
            <p class="triplex-row">
              {{ $t("airdrop.NumberofWinners") }}:{{
                item.list3.winnerAddressNumber
              }}
            </p>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div :class="['switchover', carouselitem.length < 2 ? 'distance' : '']">
      <div class="Ongoing">
        <p
          :class="['left', airdropState == 'Underway' ? 'left-active' : '']"
          @click="leftActive('Underway')"
        >
          {{ $t("airdrop.OngoingAirdrops") }}
        </p>
        <p
          :class="['left', airdropState == 'Upcoming' ? 'left-active' : '']"
          @click="leftActive('Upcoming')"
        >
          {{ $t("airdrop.UpcomingAirdrops") }}
        </p>
        <p
          :class="['left', airdropState == 'Finished' ? 'left-active' : '']"
          @click="leftActive('Finished')"
        >
          {{ $t("airdrop.FinishedAirdrops") }}
        </p>
      </div>
    </div>
    <el-table
      @row-click="clickData"
      :data="tableData"
      class="#wrap"
      style="width: 100%; background: transparent"
      :header-cell-style="{ background: 'transparent', color: '#5D5D5F' }"
      :row-class-name="TableRowClass"
    >
      <template slot="empty">
        <svg-icon iconClass="empty" class="empty-icon"></svg-icon>
      </template>

      <el-table-column type="index" label="ID" width="120" align="center">
      </el-table-column>
      <el-table-column :label="$t('airdrop.Project')" align="left">
        <template slot-scope="scope">
          <div class="project">
            <img
              style="width: 20px; height: 20px; border-roduis: 50%"
              :src="
                scope.row.logo ? scope.row.logo : '../../static/images/18.png'
              "
              alt=""
              @error="imageLoad(scope.row.logo, scope.row)"
            />
            <el-tooltip :content="scope.row.name" placement="top">
              <el-button>{{ scope.row.name }}</el-button>
            </el-tooltip>
            <!-- <img style="width:20px;height:20px;border-roduis:50%;" :src="scope.row.logo ? scope.row.logo : '../../static/images/18.png'" alt="" @error="imageLoad(scope.row.logo,scope.row)"> -->
            <!-- <span style="margin-left: 10px">{{ scope.row.name }}</span> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('airdrop.Participated')" align="center">
        <template slot-scope="scope">
          <span>{{ $tool.formatMonenyNumber(scope.row.participants) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="winnerAddressNumber"
        :label="$t('airdrop.NumberofWinners')"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{
            $tool.formatMonenyNumber(scope.row.winnerAddressNumber)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="totalAirdropAmount"
        :label="$t('airdrop.TotalAirdropAmount')"
        align="center"
      >
        <template slot-scope="scope">
          <span
            >{{ $tool.formatMonenyNumber(scope.row.totalAirdropAmount)
            }}{{ " " + scope.row.token }}</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('airdrop.Endsin')" align="center">
        <template slot-scope="scope">
          <p>
            {{
              $i18n.locale == "en"
                ? scope.row.countDownen
                : scope.row.countDownzh
            }}
          </p>
          <p style="color: #7b8087; font-size: 12px">
            {{
              $i18n.locale == "en"
                ? scope.row.airdropEndTimeen
                : scope.row.airdropEndTime
            }}
          </p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { airdroplist, airdroptop } from "@/api/require.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      active: 1,
      tableData: [],
      airdropState: "",
      pageIndex: 1,
      pageSize: 10,
      banner: {
        pageIndex: 1,
        pageSize: 8,
      },
      carouselitem: [],
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  created() {
    this.airdropState = this.$store.state.ongoing
      ? this.$store.state.ongoing
      : "Underway";
    this.airdroplist();
    this.airdroptop();
  },
  mounted() {
    addEventListener("scroll", this.handleScroll, true);
  },

  methods: {
    handleScroll() {
      let offsetHeight = document.querySelector(
        ".el-table__body-wrapper"
      ).offsetHeight; //窗口可视高度
      let innerHeight = document.querySelector(".el-table__body").offsetHeight; //元素的高度
      let scrollTop = document.querySelector(
        ".el-table__body-wrapper"
      ).scrollTop; //滚动条滚动距离
      let bottomOfWindow = scrollTop + offsetHeight === innerHeight;

      if (bottomOfWindow) {
        //用户下拉触底
        setTimeout(() => {
          this.pageIndex += 1;
          this.airdroplist();
        }, 500);
      }
    },

    imageLoad(src, row) {
      var Img = new Image();
      Img.src = src;
      if (Img.fileSize > 0 || (Img.width > 0 && Img.height > 0)) {
      } else {
        row.logo = require("../../static/images/18.png");
      }
    },

    async airdroptop() {
      let res = await airdroptop({
        pageIndex: this.banner.pageIndex,
        pageSize: this.banner.pageSize,
      });
      if (res.code === 0 && res.data.length > 0) {
        let obj = {};
        let obj1 = {};
        for (let i = 0; i < res.data.length; i++) {
          if (i < 4) {
            obj["list" + i] = res.data[i];
          } else {
            obj1["list" + (i - 4)] = res.data[i];
          }
        }
        // obj和obj1 都不为{}
        if (JSON.stringify(obj1) != "{}" && JSON.stringify(obj) != "{}") {
          this.carouselitem.push(obj, obj1);
        } else if (JSON.stringify(obj) != "{}") {
          this.carouselitem.push(obj);
        } else {
          this.carouselitem = [];
        }
      }
    },

    // 倒计时
    countTime(curStartTime) {
      // 获取当前时间
      var day, hour, min, second;
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        second = s < 10 ? "0" + s : s;
      } else {
        return {
          en: 0 + " day " + 0 + " hour",
          zh: 0 + "天" + 0 + "小时",
        };
      }

      return {
        en: day + " day " + hour + " hour",
        zh: day + "天" + hour + "小时",
      };
    },

    async airdroplist() {
      let res = await airdroplist({
        airdropState: this.airdropState,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      });
      if (res.code === 0 && res.data.length > 0) {
        res.data.forEach((element) => {
          element["countDownzh"] = this.countTime(element.airdropEndTime).zh;
          element["countDownen"] = this.countTime(element.airdropEndTime).en;
          element["airdropEndTimeen"] = this.$tool.conversion(
            element.airdropEndTime,
            1
          );
        });
        console.log(res.data);
        this.tableData.push(...res.data);
      }
    },

    clickData(row, event, column) {
      this.Tothedetails(row.id);
    },

    Tothedetails(id) {
      this.$router.push("/airdrop-details?id=" + id);
    },

    leftActive(index) {
      this.airdropState = index;
      this.$store.commit({
        type: "setsetaddress",
        val: index,
      });
      this.tableData = [];
      this.airdroplist();
    },

    TableRowClass({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "statistics-waring-single";
      } else {
        return "statistics-waring-both";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.airdrop {
  .Airdrop-logo {
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }
  /deep/.el-carousel {
    .el-carousel__indicators {
      .el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
  /deep/.el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #30343c;
  }
  /deep/.el-table {
    // height: 534px;
    margin-top: 22px;
    border-radius: 20px;
    .el-table__body-wrapper {
      height: calc(100vh - 307px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
        background: #fff;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #26e7ae;
      }
      &::-o-scrollbar {
        width: 4px;
        background: #fff;
        border-radius: 20px;
      }
      &::-o-scrollbar-thumb {
        border-radius: 10px;
        background: #26e7ae;
      }
      &::-ms-scrollbar {
        width: 4px;
        background: #fff;
        border-radius: 20px;
      }
      &::-ms-scrollbar-thumb {
        border-radius: 10px;
        background: #26e7ae;
      }
      scrollbar-color: #26e7ae #fff; //设置滚动条颜色
      scrollbar-width: thin;
    }
    .project {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .el-button {
        margin-left: 10px;
        background: initial;
        border: none;
        outline: none;
        padding: 0;
        color: #fff;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    // .cell{
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;

    // }
    tr {
      background: initial;
    }
    tr:hover > td {
      background: #383d43;
    }
    .is-leaf {
      border-bottom: #30343c;
    }
    &::before {
      background-color: initial;
    }
    .statistics-waring-single {
      // background: #20225B;
      color: #f0f0f0;
    }
    .statistics-waring-both {
      // background: #272964;
      color: #f0f0f0;
    }
  }
  .switchover {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    .Ongoing {
      // width: 360px;
      height: 38px;
      // background: #20225b;
      border-radius: 6px;
      display: flex;
      align-items: center;
      color: #7a8087;
      .left-active {
        // background: #20e1a8;
        // border-radius: 6px;
        color: #f0f0f0;
        border-bottom: 2px solid #20e1a8;
      }
      p {
        cursor: pointer;
        // width: 50%;
        margin-right: 20px;
        height: 38px;
        font-size: 14px;
        color: #7a8087;
        line-height: 38px;
        text-align: center;
      }
    }
  }
  .distance {
    margin-top: 20px;
  }
  .banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box {
      width: 24%;
      height: 140px;
      border-radius: 20px;
      padding: 20px;
      box-sizing: border-box;
      cursor: pointer;
      .triplex-row {
        margin-top: 5px;
        font-size: 12px;
        color: #ffffff;
      }
      .The-second-row {
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .a-row {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
        .shady {
          width: 35px;
          height: 35px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 27px;
          height: 27px;
          border-radius: 50%;
        }
      }
    }
    .box1 {
      background: url("../../static/images/box.png") 100% center/100% no-repeat;
    }
    .box2 {
      background: url("../../static/images/box1.png") 100% center/100% no-repeat;
    }
    .box3 {
      background: url("../../static/images/box2.png") 100% center/100% no-repeat;
    }
    .box4 {
      background: url("../../static/images/box3.png") 100% center/100% no-repeat;
    }
    .wid100 {
      width: 100%;
      background: url("../../static/images/banner/orange3.png") 100% center/100%
        no-repeat;
    }
    .wid32 {
      width: 32%;
      background: url("../../static/images/banner/orange1.png") 100% center/100%
        no-repeat;
    }
    .box2-wid32 {
      width: 32%;
      background: url("../../static/images/banner/blue1.png") 100% center/100%
        no-repeat;
    }
    .box3-wid32 {
      width: 32%;
      background: url("../../static/images/banner/qblue1.png") 100% center/100%
        no-repeat;
    }

    .wid50 {
      width: 49%;
      background: url("../../static/images/banner/orange2.png") 100% center/100%
        no-repeat;
    }
    .box2-wid50 {
      width: 49%;
      background: url("../../static/images/banner/blue2.png") 100% center/100%
        no-repeat;
    }
  }
}
</style>
